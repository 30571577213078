(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/get-json-ld/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/utils/get-json-ld/assets/javascripts/init.js');

'use strict';

const logger = svs.core.log.getLogger('component:get_json_ld_init');


function init(options) {
  if (!options.jsonLD) {
    logger.error('Missing jsonLD');
    return;
  }
  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.text = options.jsonLD;
  document.body.appendChild(script);
}

const jsonLdTags = document.querySelectorAll('[data-json-ld]');
jsonLdTags.forEach(tag => {
  init({
    jsonLD: tag.getAttribute('data-json-ld')
  });
});

 })(window);