(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/get-json-ld/assets/javascripts/get-json-ld.js') >= 0) return;  svs.modules.push('/components/utils/get-json-ld/assets/javascripts/get-json-ld.js');
"use strict";


const isServer = typeof exports === 'object';
let logger;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('utils:get-json-ld-string');
} else {
  logger = svs.core.log.getLogger('utils:get-json-ld-string');
}

function durationFormatter(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  return "PT".concat(minutes, "M").concat(seconds % 60, "S");
}
const validKeys = ['uploadDate', 'description', 'name', 'thumbnailUrl', 'duration'];

const getJsonLDString = videoData => {
  if (!Object.keys(videoData) || Object.keys(videoData).length === 0) {
    logger.warn('No data was passed to getJsonLDString');
    return null;
  }
  if (!videoData.uploadDate) {
    logger.warn('uploadDate is not set, setting it to current date. This is required for a valid JSON-LD tag.');
    videoData.uploadDate = new Date().toLocaleString('sv-SE').replace(/ /g, 'T');
  }
  const keys = Object.keys(videoData);
  const invalidKeys = keys.filter(key => validKeys.indexOf(key) === -1);
  if (invalidKeys.length > 0) {
    logger.warn("Invalid keys passed to getJsonLDString: ".concat(invalidKeys.join(', ')));
    return null;
  }
  try {
    const jsonLD = keys.reduce((result, key) => {
      if (key in videoData && videoData[key] !== null && videoData[key] !== undefined) {
        if (key === 'duration') {
          result[key] = durationFormatter(videoData[key]);
        } else {
          result[key] = videoData[key];
        }
      }
      return result;
    }, {
      '@context': 'https://schema.org/',
      '@type': 'VideoObject'
    });
    return JSON.stringify(jsonLD);
  } catch (error) {
    logger.error('Failed to parse data in getJsonLDString', error);
    return null;
  }
};
if (isServer) {
  module.exports = getJsonLDString;
} else {
  svs.utils.getJsonLDString = getJsonLDString;
}

 })(window);